<template>
  <div class="body fixed mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <!-- <div class="spinner"><div><div></div></div></div> -->
    <!-- Header -->
    <Spinner :spin="spin" />
    <section class="header activity_questions">
      <a
        class="icon iconButton third"
        @click.prevent="$router.push('/home')"
      >
        <font-awesome-icon
          icon="fa-solid fa-chevron-left"
          size="lg"
        ></font-awesome-icon>
      </a>
      <span class="fL nowrap">{{ title }}</span>
      <div class="icon iconButton"></div>
    </section>
    <!-- Main -->
    <section class="main activity_questions">
      <div class="search-wrap">
        <div class="search mx-auto">
          <SearchIcon />
          <input type="text" class="w-full" placeholder="搜尋關鍵字" v-model="searchTerm" />
        </div>
        <div class="uide-filter-wrap mt-2 mx-auto" @click.stop>
                    <a
                      class="uide-filter"
                      @click.prevent="showRewardTypeFilter = true"
                    >
                        <span class="">{{ rewardType.name }}</span>
                        <div class="icon ">
                            <font-awesome-icon
                                icon="fa-solid fa-caret-down"
                                size="xs"
                            />
                        </div>
                    </a>                    
                    <a
                      class="uide-filter"
                      @click.prevent="showDepartmentFilter = true"
                    >
                        <span class="">{{ departmentFilter?.name || '' }}</span>
                        <div class="icon ">
                            <font-awesome-icon icon="fa-solid fa-caret-down" size="xs" />
                        </div>
                    </a>
                </div>
      </div>
      <div v-if="filterCampaigns.length > 0" class="mt-2">
        <a
          class="news flexH width margin between mb-2"
          @click.prevent="toDetail(campaign)"
          v-for="(campaign, index) in filterCampaigns"
          :key="'campaign' + index"
        >
          <div class="uide-mission-card">
            <div class="uide-mission-card-container">
              <div class="uide-mission-card-top">
                <div class="uide-mission-card-top-chip">
                  {{ campaign.departmentName }}
                </div>
                <div>{{ tsToDate(campaign.campaignStartTime) }}</div>
              </div>
              <div class="uide-mission-card-title">
                {{ campaign.campaignName }}
              </div>
              <div class="ellipsis-two-line">
                {{ parseContent(campaign.campaignDesc) }}
              </div>
              <div class="flex gap-1 items-center">
                <img width="20" height="20" src="@/assets/icon/uide/coin.svg" />
                <span>雲林幣{{ campaign.reward_point }}枚</span>
              </div>
              <div class="flex gap-1 items-center justify-between">
                <ProgressBar
                  style="width: 228px"
                  :progress="((campaign.reward_total - campaign.reward_remain) / campaign.reward_total) * 100"
                />
                <div
                  :class="[
                    'uide-mission-card-progress-btn',
                    'status' + getStatusId(campaign),
                  ]"
                >
                  {{ getStatusName(campaign) }}
                </div>
              </div>
              <div class="flex gap-1 items-center">
                <img width="16" height="16" src="@/assets/icon/uide/people.svg" />
                <span class="uide-mission-card-user"
                  >獎勵剩餘人數 {{ campaign.reward_remain > 0 ? campaign.reward_remain : 0 }} 名 / 總人數
                  {{ campaign.reward_total }} 名</span
                >
              </div>
              <img
                src="@/assets/icon/bell-v2.svg"
                :class="[
                  'uide-mission-card-bell',
                  { hide: !campaign.unread },
                ]"
              />
            </div>
          </div>
          <!-- <div class="info flexV padding margin height">
            <span class="fL title">{{ campaign.campaignName }}</span>
            <span class="fS gray">{{ campaign.departmentName }}</span>
            <span class="fS double">{{ campaign.campaignDesc }}</span>
            <span class="fS gray"
              >{{ tsToDate(campaign.campaignStartTime) }} -
              {{ tsToDate(campaign.campaignEndTime) }}</span
            >
          </div> -->
        </a>
      </div>
      <div v-else>
        <!-- 無內容 -->
        <div class="listEmpty flexV width center middle">
          <div class="icon secondary">
            <font-awesome-icon
              icon="fa-solid fa-dice-d6"
              size="10x"
            ></font-awesome-icon>
          </div>
          <!-- <span class="fXXL">本日沒有問答</span> -->
          <span class="fL gray">目前沒有{{ title }}！</span>
          <!-- <span class="fL gray">感謝參與回答，您已經回答過囉！</span> -->
        </div>
      </div>
    </section>
    <FilterPopup
            v-if="showRewardTypeFilter"
            @closePopup="showRewardTypeFilter = false"
            v-slot="pop"
        >
            <section class="popHeader">
                <span class="fL">獎勵類型</span>
            </section>
            <section class="popMain" @click.prevent="pop.close">
                <a
                    v-for="(category, index) in rewardTypes"
                    :key="'category_' + index"
                    :class="{ active: rewardType.id === category.id }"
                    @click.prevent="changeRewardTypeFilter(category)"
                    ><span>{{ category.name }}</span></a
                >
            </section>
        </FilterPopup>
        <FilterPopup
            v-if="showDepartmentFilter"
            @closePopup="showDepartmentFilter = false"
            v-slot="pop"
        >
            <section class="popHeader">
                <span class="fL">部門</span>
            </section>
            <section class="popMain" @click.prevent="pop.close">

                <a
                    v-for="(region, index) in departments"
                    :key="'region_' + index"
                    :class="{ active: departmentFilter.id == index }"
                    @click.prevent="changeDepartmentFilter(region, index)"
                    ><span>{{ region }}</span></a
                >
            </section>
        </FilterPopup>
        <AlertDialog
          v-if="showAlert"
          :title="alert.title"
          :content="alert.message"
          @close="onCloseAlertDialog"
        />
  </div>
</template>
<script>
import moment from "moment-timezone";
import { mapState } from "vuex";
import Spinner from "@/components/Spinner";
import SearchIcon from "@/components/Icons/SearchIcon";
import ProgressBar from '@/components/ProgressBar.vue';
import FilterPopup from '@/components/FilterPopup.vue';
import AlertDialog from '@/components/Dialog/AlertDialog.vue'

export default {
  components: {
    Spinner,
    SearchIcon,
    ProgressBar,
    FilterPopup,
    AlertDialog
  },
  name: "",
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      campaigns: [],
      spin: false,
      statusNames: {
        0: "快來搶獎勵",
        1: "已領取獎勵",
        2: "獎勵已搶完",
        3: "您已完成"
      },
      showAlert: false,
      alert: {
        title: '',
        message: ''
      },
      searchTerm: '',
      rewardType: {id: 0, name: '不限獎勵'},
      rewardTypes: [{id: 0, name: '不限獎勵'}, {id: 1, name: '有獎勵'}],
      showRewardTypeFilter: false,
      departmentFilter: {id: 0, name: '全部'},
      showDepartmentFilter: false,
      departments: [],
    };
  },
  mounted() {
    this.getCampaigns();
  },
  computed: {
    ...mapState(["user", "userInfo"]),
    campaignType() {
      return this.$route.path.includes("on-site-activities")
        ? "check-in"
        : "qna";
    },
    title() {
      return this.campaignType === "check-in" ? "實體活動報到" : "政令大聲公";
    },
    filterCampaigns() {
      // completed-no-reward
      return (this.searchTerm ? 
        this.campaigns.filter(campaign => campaign.campaignName.includes(this.searchTerm))
       : this.campaigns).filter(campaign => (this.rewardType.id === 0 ? true : campaign.reward_point > 0)).filter(campaign => (this.departmentFilter.id === 0 ? true : campaign.departmentName === this.departmentFilter.name))
    }
  },
  methods: {
    parseContent(content) {
        return content.replace(/(<([^>]+)>)/ig, "")
    },
    changeDepartmentFilter(dep, inx) {
      this.departmentFilter = {id: inx, name: dep}
    },
    changeRewardTypeFilter(type) {
      this.rewardType = JSON.parse(JSON.stringify(type)) // deep copy type
    },
    tsToDate(ts) {
      return moment(ts * 1000).format("YYYY/MM/DD");
    },
    getDummyCampaigns() {
      let result = {
        uuid: "line.U3ff10b3a06117cd203d0292637c34de3",
        memberId: 208,
        campaigns: [
          {
            campaignId: "M_RdROw7eY5B",
            campaignName: "測試幸運輪盤任務",
            campaignDesc: "輪盤抽獎。Yay!!",
            campaignStatus: "approved",
            campaignPrototype: "minigame",
            campaignStartTime: 1652284800,
            campaignEndTime: 1656604799,
            campaignCreatedAt: 1652322816,
            missions: [
              {
                missionId: 44,
                missionName: "幸運輪盤（小遊戲任務）",
                missionDesc: "As title",
                participation: [
                  {
                    data: {
                      rewardIds: ["38"],
                    },
                    status: "completed",
                    completedAt: 1652341991,
                  },
                ],
              },
            ],
          },
          {
            campaignId: "M_r5PDKD0m58",
            campaignName: "PrePOB測試任務：抽獎小遊戲",
            campaignDesc: "As title",
            campaignStatus: "approved",
            campaignPrototype: null,
            campaignStartTime: 1652112000,
            campaignEndTime: 1656604799,
            campaignCreatedAt: 1652167000,
            missions: [
              {
                missionId: 36,
                missionName: "抽獎小遊戲",
                missionDesc: "好禮大方送",
                participation: [
                  {
                    data: {
                      rewardIds: ["31"],
                    },
                    status: "completed",
                    completedAt: 1652170978,
                  },
                ],
              },
            ],
          },
          {
            campaignId: "M_xd4wXAwvkj",
            campaignName: "PrePOB測試任務：推薦",
            campaignDesc:
              "To recommend or not to recommend, that is the question.",
            campaignStatus: "approved",
            campaignPrototype: null,
            campaignStartTime: 1652025600,
            campaignEndTime: 1656604799,
            campaignCreatedAt: 1652070487,
            missions: [
              {
                missionId: 31,
                missionName: "測試推薦任務 之 推薦人關卡",
                missionDesc: "推薦他人成功可以領取獎項",
                participation: [
                  {
                    status: "completed",
                    completedAt: 1652092171,
                  },
                  {
                    status: "completed",
                    completedAt: 1652092477,
                  },
                ],
              },
              {
                missionId: 32,
                missionName: "推薦人關卡",
                missionDesc: "推薦他人成功可以領取獎項",
              },
              {
                missionId: 34,
                missionName: "被推薦人關卡",
                missionDesc: "接受他人推薦連結並成功註冊者可以領取獎項",
              },
              {
                missionId: 35,
                missionName: "註冊關卡",
                missionDesc: "成功註冊者可以領取獎項",
              },
            ],
          },
          {
            campaignId: "M_p5O76qR854",
            campaignName: "註冊任務",
            campaignDesc: "成功註冊雲林幣即可獲得100點",
            campaignStatus: "approved",
            campaignPrototype: null,
            campaignStartTime: 1651132800,
            campaignEndTime: 1656575999,
            campaignCreatedAt: 1651129632,
            missions: [
              {
                missionId: 22,
                missionName: "會員註冊任務",
                missionDesc: "只要成功註冊會員即可獲得，每人限領一次",
                participation: [
                  {
                    data: {},
                    status: "completed",
                    completedAt: 1652253394,
                  },
                ],
              },
            ],
          },
          {
            campaignId: "M_qkwQ9ygK5L",
            campaignName: "2022問答任務debug測試",
            campaignDesc: "答對有獎，限量三名",
            campaignStatus: "approved",
            campaignPrototype: "test",
            campaignStartTime: 1650816000,
            campaignEndTime: 1656575999,
            campaignCreatedAt: 1650862201,
            missions: [
              {
                missionId: 16,
                missionName: "問答任務 測試",
                missionDesc: "有獎問答 測試",
                participation: [
                  {
                    status: "completed",
                    completedAt: 1651387810,
                  },
                ],
              },
            ],
          },
        ],
      };
      let promise = new Promise((resolve) => {
        setTimeout(() => {
          resolve({ data: result });
        }, 1000);
      });
      return promise;
    },
    getCampaignsAPI() {
      const publisher = this.$route.meta.publisher;
      const param = publisher ? `&publisherType=${publisher}` : "";
      const apiHost = process.env.VUE_APP_API_HOST;
      let config = {
        url: `${apiHost}/campaigns/v1/merchants/yunlin/participations/uuid/${this.user.userId}/member_id/${this.userInfo.memberId}?includeNonJoined=true&includeInactive=true${param}&prototype=qna&return=list`,
        method: "GET",
      };
      return this.$http(config);
    },
    getCampaigns() {
      this.spin = true;
      const now = moment();
      return (
        this.getCampaignsAPI()
          // this.getDummyCampaigns()
          .then((res) => {
            console.log(res)
            this.campaigns = res.data.campaigns.filter(
              (campaign) => {
                const campaignEndTime = moment(campaign.campaignEndTime * 1000);

                if (campaignEndTime.isBefore(now)) {
                    return false;
                }

                if (campaign.campaignStatus !== 'approved') {
                    return false
                }

                return campaign.campaignPrototype === this.campaignType &&
                campaign.campaignVisibility == "public"
              }
            );
            this.departments = new Set(["全部", ...(this.campaigns.map((c) => c.departmentName))]);
            this.sortCampaigns();
            console.log("campaigns: ", this.campaigns);
            return res;
          })
          .finally(() => (this.spin = false))
      );
    },
    sortCampaigns() {
      let incompleteCampaigns = this.campaigns.filter(
        (c) => c.completeness == "incomplete"
      );
      let notParticipatingCampaigns = this.campaigns.filter(
        (c) => c.completeness == "not-participating"
      );
      let completedCampaigns = this.campaigns.filter(
        (c) => c.completeness == "completed"
      );
      let completedNoRewardCampaigns = this.campaigns.filter(c => c.completeness == 'completed-no-reward');

      function sortByTsDesc(b, a) {
        return a.campaignStartTime - b.campaignStartTime;
      }
      incompleteCampaigns.sort(sortByTsDesc);
      notParticipatingCampaigns.sort(sortByTsDesc);
      completedCampaigns.sort(sortByTsDesc);
      this.campaigns = incompleteCampaigns
        .concat(notParticipatingCampaigns)
        .concat(completedCampaigns)
        .concat(completedNoRewardCampaigns);
    },
    isOverToday(campaign) {
      const today = moment();
      const endTime = moment(campaign.campaignEndTime * 1000);
      return endTime.isBefore(today);
    },
    onCloseAlertDialog() {
      this.showAlert = false;
      this.alert = {
        title: '',
        message: ''
      }
      this.getCampaigns();
    },
    toDetail(campaign) {
      if (this.isOverToday(campaign)) {
        this.alert = {
          title: '提醒',
          message: '任務已下架'
        }
        this.showAlert = true
        return;
      }

      const path =
        this.campaignType === "check-in"
          ? `/service/check-in/${campaign.campaignId}`
          : `/service/question-detail/${campaign.campaignId}`;
      this.$router.push({ path });
    },
    // calCompletedMissions(campaign) {
    //     if (
    //         campaign &&
    //         campaign.missions &&
    //         campaign.missions.length > 0 &&
    //         campaign.missions.some((m) => m.participation)
    //     ) {
    //         return campaign.missions.filter((m) => {
    //             if (
    //                 m.participation &&
    //                 m.participation.length > 0 &&
    //                 m.participation[0].status == "completed"
    //             ) {
    //                 return true;
    //             }
    //             return false;
    //         }).length;
    //     }
    //     return 0;
    // },
    // calMissions(campaign) {
    //     if (
    //         campaign &&
    //         campaign.missions &&
    //         campaign.missions.length >= 0
    //     ) {
    //         return campaign.missions.length;
    //     }
    //     return 0;
    // },
    // checkCampaignCompleted(campaign) {
    //     return (
    //         this.calCompletedMissions(campaign) ==
    //         this.calMissions(campaign)
    //     );
    // },
    checkRewardCompleted(campaign) {
      return campaign.campaignBudget.availablePoints <= 0;
    },
    showRewordInsufficient(campaign) {
      const {
        campaignBudget: { availablePoints, usedPoints },
        hasCompleted,
      } = campaign;

      return (
        !hasCompleted &&
        availablePoints > 0 &&
        availablePoints <= (availablePoints + usedPoints) * 0.05
      );
    },
    getStatusId(campaign) {
      if (campaign.campaignPrototype === 'qna' && !!campaign.hasCompleted && !campaign.reward_status) {
        return 3
      }
      return campaign.reward_status ? 1 : campaign.hasCompleted ? 3 : (campaign.reward_point > 0 && campaign.reward_remain <= 0 ? 2 : 0)
    },
    getStatusName(campaign) {
      const idx = this.getStatusId(campaign);
      return this.statusNames[idx] || "Unknown";
    },
  },
};
</script>
<style scoped lang="scss">
.search-wrap {
  background: #fff;
  margin: 0 -10px;
  padding-bottom: 10px;
  padding-top: 10px;
  .search {
    border: 1px solid #eeeeee;
    border-radius: 99px;
    display: flex;
    align-items: center;
    gap: 4px;
    padding-left: 16px;
    width: 92%;
    box-sizing: border-box;
    input {
      height: 40px;
    }
  }
}
.uide-filter-wrap {
  width: 92%;
}
.uide-mission-card {
  width: 100%;
  padding: 0;
}
.uide-mission-card-container {
  padding: 12px 16px;
}
</style>
